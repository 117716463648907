<template>
  <div class="logo">
    <svg preserveAspectRatio="xMidYMid meet" data-bbox="0 0 111.497 25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.497 25" height="25" width="112" data-type="color" role="presentation" aria-hidden="true" aria-labelledby="svgcid--jecrafsvgv7t"><title id="svgcid--jecrafsvgv7t"></title>
      <g>
          <path fill="#131616" d="M45.655 24.737a17.102 17.102 0 0 1-5.743-.977 17.47 17.47 0 0 1-5.78.977c-4.252 0-9.31-1.723-9.31-9.917V1.342C24.829.606 25.443.01 26.204 0h2.033c.773 0 1.4.605 1.4 1.35v13.48c0 4.771 1.93 5.747 4.824 5.747a13.36 13.36 0 0 0 2.954-.437V1.351c0-.36.149-.705.413-.959.265-.253.623-.394.996-.392h2.134c.773 0 1.4.605 1.4 1.35v18.777c.96.256 1.948.404 2.944.44 3.413 0 4.825-1.665 4.825-5.747V1.342C50.132.598 50.759-.002 51.53 0h2.044c.773 0 1.4.605 1.4 1.35v13.48c0 8.185-5.067 9.908-9.318 9.908Z" clip-rule="evenodd" fill-rule="evenodd" data-color="1"></path>
          <path fill="#131616" d="M0 10.783C0 4.05 3.807.027 10.187.027c6.407 0 10.232 4.023 10.239 10.756v3.196c0 6.736-3.86 10.758-10.24 10.758C3.808 24.737 0 20.715 0 13.979v-3.196Zm10.186 9.7c3.547 0 5.347-2.189 5.347-6.505v-3.196c0-4.313-1.828-6.505-5.347-6.505-3.518 0-5.302 2.19-5.302 6.505v3.196c0 4.316 1.756 6.505 5.302 6.505Z" clip-rule="evenodd" fill-rule="evenodd" data-color="1"></path>
          <path fill="#131616" d="M110.594.002H96.187a1.147 1.147 0 0 0-.827.291 1.067 1.067 0 0 0-.355.778V2.91c0 .51.378.947.897 1.039h4.912v19.57c0 .662.555 1.198 1.241 1.198h2.392c.685 0 1.241-.536 1.241-1.198V3.955h4.626c.306.016.604-.09.827-.292.224-.201.352-.482.356-.777V1.04a1.069 1.069 0 0 0-.903-1.04Z" clip-rule="evenodd" fill-rule="evenodd" data-color="1"></path>
          <path fill="#131616" d="M81.645 23.348V.993c0-.548.445-.992.993-.992l2.966.005c.546 0 .99.444.99.99v22.352c0 .79-.565 1.429-1.26 1.429h-2.429c-.696 0-1.26-.64-1.26-1.43Z" data-color="1"></path>
          <path fill="#131616" d="M83.499 20.813h11.149a.99.99 0 0 1 .99.992l-.001 1.982a.99.99 0 0 1-.991.99H83.499c-.41 0-.743-.452-.743-1.01v-1.945c0-.557.332-1.01.743-1.01Z" data-color="1"></path>
          <path fill="#131616" d="m67.735 5.892-7.493 17.621a2.19 2.19 0 0 1-.763 1.041 1.808 1.808 0 0 1-1.075.347c-.625 0-1.087-.174-1.388-.52-.3-.347-.45-.775-.45-1.284 0-.208.034-.428.103-.659l8.638-20.743c.185-.463.462-.821.832-1.076a1.88 1.88 0 0 1 1.249-.312c.416 0 .798.127 1.145.382.37.231.636.566.797 1.006l8.534 20.327c.115.3.173.578.173.832 0 .625-.208 1.122-.624 1.492-.393.37-.833.555-1.318.555-.417 0-.798-.127-1.145-.382-.324-.254-.578-.6-.763-1.04L66.729 6.1l1.006-.208Zm-6.417 13.39 1.907-3.99h9.366l.66 3.99H61.317Z" data-color="1"></path>
          <path fill="#131616" d="m67.19 7.174-3.452 8.118h6.936L67.19 7.174Zm3.333 8.02L67.19 7.425l-3.302 7.767h6.635Zm-10.28 8.32a2.2 2.2 0 0 1-.764 1.04 1.809 1.809 0 0 1-1.075.347c-.625 0-1.087-.174-1.388-.52-.3-.347-.45-.775-.45-1.284 0-.208.034-.428.103-.659l8.638-20.743c.185-.463.462-.821.832-1.076a1.88 1.88 0 0 1 1.249-.312c.416 0 .798.127 1.145.382.37.231.636.566.797 1.006l8.534 20.327c.115.3.173.578.173.832 0 .625-.208 1.122-.624 1.492-.393.37-.833.555-1.318.555-.417 0-.798-.127-1.145-.382-.324-.254-.578-.6-.763-1.04l-1.801-4.197H62.042l-1.8 4.231Zm1.864-4.133-1.773 4.169a2.293 2.293 0 0 1-.796 1.084h-.001c-.34.244-.72.366-1.133.366-.642 0-1.137-.179-1.463-.555-.318-.366-.475-.818-.475-1.348 0-.219.037-.448.109-.687l.001-.005 8.639-20.747c.191-.479.48-.854.868-1.12l.002-.002a1.98 1.98 0 0 1 1.308-.328 1.99 1.99 0 0 1 1.195.399c.388.243.666.595.835 1.051l8.533 20.328c.12.31.18.6.18.868 0 .649-.217 1.174-.656 1.565-.41.385-.872.581-1.385.581-.438 0-.84-.134-1.204-.4l-.002-.003c-.34-.266-.603-.627-.793-1.08l-1.776-4.136H62.107Z" clip-rule="evenodd" fill-rule="evenodd" data-color="1"></path>
      </g>
    </svg>
  </div>
  <div class="result" v-if="accountToken && personToken">
    <div>Nous vous remercions pour ces informations.<br />Merci de communiquer à votre interlocuteur Ownest les informations suivantes:</div><br />
    <div><strong>Account:</strong> {{accountToken}}</div>
    <div><strong>Person:</strong> {{personToken}}</div>
  </div>
  <form v-else class="my-form" method="post" @submit.prevent="handleForm">
    <div>Merci de renseigner les informations suivantes:</div><br />
    <div class="infos">
      <div class="group">
        <fieldset>
            <legend>Business</legend>
            <table>
              <tr>
                <td>Business Name</td>
                <td><input class="inp-company-name" /></td>
              </tr>
              <tr>
                <td>Street Address Line 1</td>
                <td><input class="inp-company-street-address1" /></td>
              </tr>
              <tr>
                <td>City</td>
                <td><input class="inp-company-city" /></td>
              </tr>
              <tr>
                <td>State</td>
                <td><input class="inp-company-state" /></td>
              </tr>
              <tr>
                <td>Postal Code</td>
                <td><input class="inp-company-zip" /></td>
              </tr>
            </table>
        </fieldset>
      </div>
      <div class="group">
        <fieldset>
            <legend>Representative</legend>
            <table>
              <tr>
                <td>Representative First Name</td>
                <td><input class="inp-person-first-name" /></td>
              </tr>
              <tr>
                <td>Representative Last Name</td>
                <td><input class="inp-person-last-name" /></td>
              </tr>
              <tr>
                <td>Street Address Line 1</td>
                <td><input class="inp-person-street-address1" /></td>
              </tr>
              <tr>
                <td>City</td>
                <td><input class="inp-person-city" /></td>
              </tr>
              <tr>
                <td>State</td>
                <td><input class="inp-person-state" /></td>
              </tr>
              <tr>
                <td>Postal Code</td>
                <td><input class="inp-person-zip" /></td>
              </tr>
            </table>
        </fieldset>
      </div>
    </div>
    <br /><br />
    <button>Envoyer les informations</button>
    <p>
        En cliquant sur ce bouton, vous acceptez les <a href="https://stripe.com/fr/legal/connect-account" target="_blank">Conditions d'Utilisation des Comptes Connectés Stripe</a>.
    </p>
  </form>
</template>

<script>
import { ref } from 'vue';
export default {
  name: 'App',
  setup() {
    // eslint-disable-next-line
    const stripe = Stripe('pk_live_51LAXaHGlNnVSBqK4JbfgHgCND7C4Q3GOr2DhcdT1OuZssJDqBSwH4RulGeewwXyDjJ47b7AlubKEYavtf47Nr7LR00XwY0bHIw');
    const accountToken = ref()
    const personToken = ref()
    const handleForm = async(event) => {
      event.preventDefault();

      const accountResult = await stripe.createToken('account', {
          business_type: 'company',
          company: {
          name: document.querySelector('.inp-company-name').value,
          address: {
              line1: document.querySelector('.inp-company-street-address1').value,
              city: document.querySelector('.inp-company-city').value,
              state: document.querySelector('.inp-company-state').value,
              postal_code: document.querySelector('.inp-company-zip').value,
          },
          },
          tos_shown_and_accepted: true,
      });

      const personResult = await stripe.createToken('person', {
          person: {
          first_name: document.querySelector('.inp-person-first-name').value,
          last_name: document.querySelector('.inp-person-last-name').value,
          address: {
              line1: document.querySelector('.inp-person-street-address1').value,
              city: document.querySelector('.inp-person-city').value,
              state: document.querySelector('.inp-person-state').value,
              postal_code: document.querySelector('.inp-person-zip').value,
          },
          },
      });

      if (accountResult.token && personResult.token) {
        accountToken.value = accountResult.token.id;
        personToken.value = personResult.token.id;
      }
      return false;
    }
    return {
      handleForm,
      accountToken,
      personToken
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.logo {
  margin-bottom: 50px;
}

fieldset {
  text-align: left;
}

.infos {
    display: flex;
    justify-content: space-between;
}
.group {
  width: 45%;
}
fieldset.span {
  margin-right: 20px;
}
</style>
